const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://zv7jjkwsii.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://x66v60ocf1.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://tvf5ke6dra.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'feature/CV4-2222-EHS-API-Implement-get-physical-location-hierarchy-endpoint',
    HOSTNAME: 'https://locations.testing.newmont.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.testing.newmont.forwoodsafety.com',
    WEBSOCKET: 'wss://boxrmt5ywk.execute-api.us-west-2.amazonaws.com/testing'
  },
};

export default config;